@import "../variables";

section.footer {
	display: flex;
	flex-direction: column;
	gap: 80px;
	align-items: center;

	div.contact {
		h3.title {
			font-size: 30px;
		}

		p.text {
			margin-top: 3px;
			color: $dark-gray;

			a {
				color: $accent;
				font-weight: bold;
				text-decoration: underline;
			}
		}
	}

	div.logo-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 35px;

		h3 {
			font-weight: 300;

			b {
				font-weight: bold;
			}
		}
	}

	div.socials {
		figure {
			width: 30px;
			height: 30px;

			img {
				object-fit: contain;
			}
		}
	}
}
