@import "../variables";

$cardWidth: 180px;
$cardGap: 30px;

section.skills {
	background: $dark-background;
	overflow: hidden;

	.skills-wrapper {
		display: flex;
		gap: $cardGap;
		justify-content: space-between;
		flex-wrap: nowrap;

		.skillCard {
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 20px 10px;

			width: $cardWidth;
			height: 270px;
			border: 2px solid $dark-gray;
			border-radius: 30px;

			&:first-of-type {
				border-color: $accent;
			}

			figure {
				height: 100px;
				overflow: hidden;
				margin-bottom: 20px;

				img {
					object-fit: contain;
					align-self: center;
				}
			}

			.percentage {
				font-size: 43px;
				font-weight: bold;
				color: $accent;
			}

			.title {
				font-size: 18px;
				font-weight: normal;
				white-space: nowrap;
			}
		}
	}


	// Zorgt voor de overflow van de Skill-cards
	@for $i from 3 through 6 {
		$basePadding: calc(#{$hPadding * 2} + #{$cardWidth * ($i)} + #{($i - 1) * $cardGap});


		@media only screen and (max-width: $basePadding) and (min-width: 700px) {
			.skillCard[data-nr="#{$i - 1}"] {
				display: none;
			}
		}
	}


	@media only screen and (max-width: 1440px) {
		.skills-wrapper .skillCard {
			height: 210px;
			width: 160px;

			figure {
				height: 65px;
			}

			.percentage {
				font-size: 35px;
			}

			h5.title {
				font-size: 15px;
			}
		}
	}

	@media only screen and (max-width: 700px) {
		.skills-wrapper {
			overflow-y: scroll;
			padding-bottom: 20px;
		}
	}

}
