@import "variables";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	width: 100vw;
	min-height: 100vh;
	overflow-x: hidden;
	background: $background;
}

body {
	color: white;
	font-family: "Inter", sans-serif;
}

::selection {
	background: $accent;
	color: white;
}

section {
	padding: $vPadding clamp($hPadding, calc((100vw - 1920px) / 2), 1000px);
	overflow: hidden;
	
	section {
		padding: 0;
	}

	h2.sectionTitle {
		display: flex;
		align-items: center;
		gap: 10px;
		color: $light-gray;
		margin-bottom: 40px;
		font-size: 20px;

		span {
			width: 10px;
			height: 5px;
			background: $light-gray;
		}
	}
}

@media only screen and (max-width: 700px) {
	section {
		padding: 2rem;
	}
}

figure.logo {
	width: 100px;
	height: 100px;
	background: $accent;
	border-radius: 0 50% 50% 50%;
	position: relative;

	&::after {
		position: absolute;
		content: "";
		width: 60%;
		height: 25%;
		bottom: 10%;
		left: 50%;
		transform: translateX(-50%);
		background: $background;
		border-radius: 0 0 30px 30px;
	}

	&::before {
		z-index: 1;
		position: absolute;
		content: "";
		width: 60%;
		height: 25%;
		bottom: 35%;
		left: 50%;
		transform: translateX(-50%);
		background: $accent;
		border-radius: 0 0 30px 30px;
		transition: transform .15s ease-in-out;
	}

	&:hover::before {
		transform: translate(-50%, 50%);
	}
}

.timeline-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 100px;
	background: $dark-background;
}

@media only screen and (max-width: 1300px) {
	.timeline-wrapper {
		grid-template-columns: 1fr;
	}
}

div.socials {
	display: flex;
	gap: 1.5rem;
}

/** Figures **/
figure {
	display: block;
}

figure img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

figure img.contain {
	object-fit: contain;
}

figure img.cover {
	object-fit: cover;
}

// Text
p {
	white-space: pre-line; // Zorgt voor een line-break bij \n
}
