@import "../variables";

section.home {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 11rem;

	h1.title {
		position: relative;
		font-size: 120px;
		font-weight: bold;
		width: max-content;
		margin: 70px 0 40px 0;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -5px;
			height: 10px;
			width: 120px;
			background: $accent;
		}

		&::after {
			content: "";
			display: inline-block;
			vertical-align: baseline;
			width: 16px;
			height: 16px;
			margin-top: 5px;
			margin-left: 5px;
			background: $accent;
			border-radius: 50%;
		}
	}

	.socials {
		opacity: 0.5;
	}

	.introduction {
		margin-top: 80px;

		.subtitle {
			font-size: 60px;
			margin-bottom: 5px;

			.Typewriter {
				display: inline;
				white-space: pre-wrap;
				font-weight: bold;
				color: $accent;
			}

			&.nowrap, &.nowrap .Typewriter {
				white-space: nowrap;
			}
		}
	}

	@media only screen and (max-width: 1440px) {
		gap: 6rem;

		h1.title {
			font-size: 100px;
			margin-bottom: 20px;
		}

		.introduction h2.subtitle {
			font-size: 45px;
		}

		.socials figure {
			width: 30px;
			height: 30px;
		}
	}

	@media only screen and (max-width: 1000px) {
		gap: 4rem;

		h1.title {
			font-size: 70px;
		}

		.introduction {
			margin-top: 10px;

			h2.subtitle {
				font-size: 35px;
			}
		}
	}


	@media only screen and (max-width: 900px) {
		h1.title {
			font-size: 50px;

			&::before {
				height: 5px;
			}
		}
	}

	@media only screen and (max-width: 700px) {
		grid-template-columns: 1fr;

		.logo {
			width: 75px;
			height: 75px;
		}

		h1.title {
			margin-top: 20px;
			font-size: 60px;
		}
	}
}
