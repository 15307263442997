.timeline {
	.timelineRow:first-of-type {
		h4.period {
			color: $accent;
		}

		.timeline-event {
			background: $accent;
		}
	}

	.timelineRow:last-of-type {
		.timeline-info {
			padding-bottom: 0;
		}
	}

	.timelineRow {
		display: grid;
		grid-template-columns: 120px 16px auto;
		gap: 2rem;

		h4.period {
			text-align: right;
			font-size: 14px;
			font-weight: normal;
			color: $light-gray;
		}

		figure.timeline-event {
			display: block;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			margin-top: 2px;
			border: 1px solid $accent;
			background: $dark-background;
			z-index: 1;
		}

		.timeline-info {
			display: flex;
			position: relative;
			flex-direction: column;
			padding-bottom: 50px;

			&::before {
				position: absolute;
				content: "";
				left: calc(-2rem - 8px);
				top: 4px;
				width: 1px;
				height: 100%;
				background: $dark-gray;
			}

			h3.title {
				font-size: 24px;
			}

			h4.subtitle {
				font-size: 16px;
				font-weight: 200;
			}

			p.text {
				margin-top: 5px;
				color: $light-gray;
				font-size: 18px;
			}
		}
	}

	@media only screen and (max-width: 550px) {
		.timelineRow {
			gap: 1rem;
			grid-template-columns: 70px 13px auto;

			figure.timeline-event {
				width: 13px;
				height: 13px;
			}

			h4.period {
				font-size: 12px;
			}

			.timeline-info {
				&::before {
					left: calc(-1rem - 7px);
				}

				h3.title {
					font-size: 18px;
				}

				h4.subtitle {
					font-size: 16px;
				}

				p.text
				 {
					font-size: 14px;
				}

			}
		}
	}
}
