@import "../variables";


section.projects {
	article {
		display: flex;
		flex-direction: row;
		gap: 2.5rem;
		flex-wrap: nowrap;
		padding: 20px 100px;
		overflow: hidden;

		&:not(:last-of-type) {
			border-bottom: 1px solid $dark-gray;
		}

		&:nth-child(even) {
			flex-flow: row-reverse;
		}

		figure {
			flex-shrink: 0;
			max-width: 500px;

			img {
				object-fit: contain;
			}
		}

		.project-info {
			display: flex;
			gap: 1rem;
			flex-direction: column;
			flex-shrink: 1;

			h3 {
				font-size: 30px;
				flex-shrink: 0;
			}

			p {
				flex-grow: 1;
			}
		}

		.skills {
			display: flex;
			gap: .5rem 1rem;
			flex-shrink: 0;
			flex-wrap: wrap;

			span {
				display: block;
				width: max-content;
				background: transparent;
				height: 38px;
				line-height: 36px;
				border-radius: 19px;
				padding: 0px 18px;
				border: 1px solid $accent;
				font-weight: bold;

				&:first-of-type {
					background: $accent;
				}
			}
		}
	}

    .more {
        text-align: center;
        font-weight: bold;
        color: $accent;
        font-size: 16px;
        margin-top: 50px;
		font-style: italic;
    }

	@media only screen and (max-width: 1300px) {
		article {
			padding: 20px 0;
		}
		
		.project-info {

			h3 {
				font-size: 25px !important;
			}

			p {
				font-size: 14px;
			}
		}

		figure {
			width: fit-content;
			height: 200px;
		}
	}

	@media only screen and (max-width: 1000px) {
		article {
			padding: 30px 0;
			flex-direction: column !important;
		}
	}
}
