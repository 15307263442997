@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

$hPadding: 100px;
$vPadding: 90px;

$accent: #ffa500;

$light-gray: #9697a0;
$dark-gray: #76767d;

$background: #333646;
$dark-background: #252734;
